@import '../../../assets/styles/index.scss';

@mixin verticalBarStyling {
  margin: 0 10px;
  width: 1px;
  color: $neutral-400;
}

@mixin socialInteraction {
  @include flexRow;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  cursor: pointer;
  color: $neutral-700;
  @include hoverOrange;
}

.post-card {
  .like-component {
    span {
      font-size: 13px;
    }
  }
  @include flexCol;
  padding: 0;
  border-radius: 16px;
  gap: 12px;
  padding: 0 !important;

  &__announcement {
    @include flexRow;
    align-items: center;
    background: $primary-100;
    color: $primary-600;
    gap: 8px;
    height: 33px;
    padding: 0 16px;
    border-radius: 8px;

    svg {
      width: 20px;
    }

    &__labelling {
      font-size: 13px;
      font-weight: 700;
    }
  }
  &__read-more-button {
    font-size: 14px;
    color: #6e6e6e;
    cursor: pointer;
    margin-left: 1px;
  }

  &__header {
    @include flexRow;
    justify-content: space-between;

    &__user {
      @include flexRow;
      gap: 10px;
      cursor: pointer;
      align-items: center;

      &__details {
        @include flexCol;
        color: $neutral-1000;
        gap: 2px;

        &__name {
          @include flexRow;
          font-size: 14px;
          color: #1d1d1d;
        }

        &__role {
          color: $neutral-700;
          font-size: 13px;
        }
      }
    }

    &__actions {
      @include flexRow;
      align-items: center;
      gap: 8px;

      &__dropdown {
        @include flexCenterContent;
        position: relative;
        cursor: pointer;
        height: 32px;
        width: 32px;
        border-radius: 50%;

        .dropdown-component {
          top: 38px;
          right: -5px;
        }
      }
    }
  }

  &__announcement-title {
    word-wrap: break-word;
    white-space: pre-wrap;
    @include wrapText;
  }

  &__sample {
    color: $neutral-1000;
    word-wrap: break-word;
    white-space: pre-wrap;
    // font-size: 14px;
    @include wrapText;

    &__read-more {
      color: $neutral-700;
    }
  }

  &__carousel {
    // height: 300px;
    z-index: 7;

    .carousel-component {
      align-items: flex-start;

      img,
      video {
        // object-fit: contain;
        // max-height: 700px;
        // width: auto;
        // height: auto;
        max-width: 600px;
        max-height: 700px;
        width: auto;
        height: auto;
        object-fit: contain;
        display: block;
      }
      @media (max-width: 768px) {
        img,
        video {
          max-width: 100%; // Tablet aur mobile par 100% width le
          height: auto;
        }
      }
    }
    // .carousel-component {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 600px;
    //   height: 700px;
    //   background-color: #ffcc99; // Soft orange background
    //   overflow: hidden;
    
    //   img,
    //   video {
    //     max-width: 100%;
    //     max-height: 100%;
    //     width: auto;
    //     height: auto;
    //     object-fit: contain;
    //     display: block;
    //   }
    // }
    
  }

  &__likeCommentList {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 15px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 8px;

      li {
        margin: 0;
        color: #6e6e6e;
        font-size: 13px;
        font-family: 'Manrope', sans-serif;
        color: #6e6e6e;
        font-weight: 600;
      }
    }
  }

  &__interactions {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    height: 35px;

    &__left {
      &__comment-button {
        background: transparent;
        border: none;
      }
      @include flexRow;
      align-items: center;
      gap: 6px;

      svg {
        height: 18px;
        width: auto;
      }

      & > span {
        @include socialInteraction;
      }
    }

    &__right {
      @include socialInteraction;

      svg {
        height: 18px;
        width: auto;
      }

      &--is-active {
        background: $primary-200;
        color: $primary-600;

        svg path {
          stroke: $primary-600;
        }
        svg {
          fill: $primary-600;
        }
      }
    }
  }
}
