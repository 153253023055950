@import '../../../assets/styles/index.scss';

.comment-component {
  @include flexRow;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  cursor: pointer;
  color: $neutral-700;
  @include hoverOrange;

  &--is-active {
    background: $primary-200;
    color: $primary-600;
    svg path {
      stroke: $primary-600;
    }
  }
}
