@import '../../../assets/styles/index.scss';

.filter-posts {
  @include flexRow;
  gap: 16px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &__options {
    @include flexRow;
    gap: 11px;
  }
  hr {
    flex: 1;
    background: $neutral-300;
  }
  &__reload {
    @include flexCenterContent;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    @include hoverOrange;
  }
}
